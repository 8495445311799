import { User as FirebaseUser, signOut } from 'firebase/auth'
import {
  Dispatch,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLocalStorage } from '@sergeimeza/foundation-react'
// import { useAuth } from '@sergeimeza/firebase-react'

import { trpc } from '../services/trpc'

import { auth } from '../services/firebase'

import { useAuth } from '@/auth/useAuthState'
import { useRouter } from 'next/router'
import { Prisma } from '../interfaces'

// Auth State enum
enum AuthState {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  LOADING = 'LOADING',
}

// Auth Context Interface
interface IAuth {
  // auth state
  state: AuthState | null
  // firebase user
  firebaseUser: FirebaseUser | null | undefined
  // app user
  appUser:
    | (Prisma.User & {
        permissions: Prisma.UserPermission[]
      })
    | null
  logOut: () => Promise<void>
}

// initial state
const initialState: IAuth = {
  state: AuthState.LOADING,
  firebaseUser: undefined,
  appUser: null,
  logOut: async () => {},
}

const SESSION_DURATION = 1000 * 60 * 15 // 15 minutes

const AuthContext = createContext<IAuth>(initialState)

const AuthProvider: FC<{
  token: string | null | undefined
  setToken: Dispatch<string | null | undefined>
  children: ReactNode
}> = ({ children, token, setToken }) => {
  const [authState, setAuthState] = useState(initialState.state)
  const [appUser, setAppUser] = useState<IAuth['appUser']>(null)
  const router = useRouter()

  const [firebaseUser, fireLoading, fireError] = useAuth(auth)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session, setSession] = useLocalStorage<number | null>('session', null)

  const logOut = useCallback(async () => {
    setAuthState(AuthState.SIGN_OUT)
    setAppUser(null)
    setToken(null)
    signOut(auth).then(async () => {
      // await router.push('/auth/sign-in')
    })
  }, [setToken])

  const fetchAppToken = trpc.useMutation('auth.login', {
    retry: false,
  })

  const fetchAppUser = trpc.useMutation('auth.user', {
    retry: false,
    onError: error => {
      if (error?.data) {
        const { code } = error.data
        if (code === 'FORBIDDEN') {
          logOut()
        }
        if (code === 'UNAUTHORIZED') {
          logOut()
        }
      }
    },
  })

  useEffect(() => {
    const run = async () => {
      if (fireLoading) {
        setAuthState(AuthState.LOADING)
        return
      }
      if (firebaseUser === undefined) {
        return
      }

      if (firebaseUser === null) {
        setAuthState(AuthState.SIGN_OUT)
        return
      }

      const { data } = await fetchAppToken.mutateAsync({
        uid: firebaseUser.uid,
      })
      setToken(data)
      setSession(Date.now() + SESSION_DURATION)

      return data
    }
    run()
  }, ['fetchAppToken', fireLoading, firebaseUser])

  useEffect(() => {
    const run = async () => {
      // TODO: doesn't work after logout
      if (!token) return

      const appUserData = await fetchAppUser.mutateAsync()

      setAppUser(appUserData)

      setAuthState(appUserData ? AuthState.SIGN_IN : AuthState.SIGN_OUT)
    }
    run()
  }, ['fetchAppUser', token])

  // update auth context value
  const value: IAuth = useMemo(
    () => ({
      state: authState,
      firebaseUser,
      appUser,
      logOut,
    }),
    [appUser, authState, firebaseUser, logOut],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// convenient hook for consuming auth context
const useAuthContext = () => useContext(AuthContext)

export { AuthProvider, AuthState, useAuthContext }
