/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react'
import { EuiProvider, EuiThemeColorMode } from '@elastic/eui'
import createCache from '@emotion/cache'

import { useTheme } from '../theme'

/**
 * Renders the UI that surrounds the page content.
 */
const Chrome: FC<{ children: ReactNode }> = ({ children }) => {
  const { colorMode } = useTheme()

  /**
   * This `@emotion/cache` instance is used to insert the global styles
   * into the correct location in `<head>`. Otherwise they would be
   * inserted after the static CSS files, resulting in style clashes.
   * Only necessary until EUI has converted all components to CSS-in-JS:
   * https://github.com/elastic/eui/issues/3912
   */
  const defaultCache = createCache({
    key: 'eui',
    container:
      typeof document !== 'undefined'
        ? (document.querySelector('meta[name="eui-styles"]') as any)
        : null,
  })
  const utilityCache = createCache({
    key: 'util',
    container:
      typeof document !== 'undefined'
        ? (document.querySelector('meta[name="eui-styles-utility"]') as any)
        : null,
  })

  return (
    <EuiProvider
      colorMode={colorMode as EuiThemeColorMode}
      cache={{ default: defaultCache, utility: utilityCache }}
      modify={{
        colors: {
          LIGHT: {
            primary: '#004AE0',
            accent: '#F04E98',
            success: '#00BFB3',
            warning: '#FE6E14',
            danger: '#F12C20',
          },
        },
      }}
    >
      {children}
    </EuiProvider>
  )
}

export { Chrome }
