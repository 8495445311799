import { FC, ReactNode, useEffect, useState } from 'react'

import { useIdleTimer } from 'react-idle-timer'
import { useCountdown } from '@sergeimeza/foundation-react'

import { Button } from '@sergeimeza/uikit-next'

import moment from 'moment'

import LemonImage from '../Images/LemonImage'
import LemonLogo from '../../../assets/img/brand/logo-lemonsquare-horizontal.svg'
import { useAuthContext, AuthState } from '../../context/AuthContext'
import { useRouter } from 'next/router'
import { toast } from '@/utils/toast'
import { useTranslationContext } from '@/context/TranslationContext'

const IDLE_TIMEOUT = 14 * 60 * 1000
const PROMPT_TIMEOUT = 60 * 1000

interface ActivityDetectionProps {
  children: ReactNode
}

const ActivityDetection: FC<ActivityDetectionProps> = ({ children }) => {
  const { translation } = useTranslationContext()

  const [isPrompted, setIsPrompted] = useState(false)
  const [isIdle, setIsIdle] = useState(false)
  const { logOut } = useAuthContext()
  const router = useRouter()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setTargetDate, formattedRes] = useCountdown({
    interval: 1000,
  }) as any

  const { activate } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],

    onPrompt: () => {
      setIsPrompted(true)
      setTargetDate(moment().add(1, 'minutes').toDate())
    },
    onIdle: () => {
      if (router.pathname.includes('auth/sign-in')) return
      setIsIdle(true)
      logOut().catch(() => null)
    },
    onActive: () => {
      if (!isIdle) {
        setIsPrompted(false)
        setIsIdle(false)
      }
    },
    onAction: () => {},
    eventsThrottle: 200,
    crossTab: true,
    startOnMount: true,
  })

  return (
    <>
      {isPrompted && !isIdle && (
        <div className='fixed z-[10000] grid h-screen w-screen items-center justify-center bg-gray-500'>
          <div className='w-[480px] rounded-lg border-[1px] border-blue-800/50 bg-white px-4 py-5 shadow-lg'>
            <div className='relative mb-5 h-14 w-full'>
              <LemonImage
                height={54}
                width={150}
                layout='fill'
                className='object-contain'
                alt='Lemon Square'
                src={LemonLogo}
              />
            </div>
            <div className='mb-2 text-2xl font-semibold'>
              まだ使用中ですか？
            </div>
            <div className='mb-5 text-base'>
              操作が行われていないようですので、まもなくログアウトいたします。{' '}
              <span className='font-bold'>残り：{formattedRes.seconds}秒</span>
            </div>
            <div className='w-full space-y-4'>
              <Button
                type='submit'
                className='h-14 w-full items-center'
                buttonType={
                  'flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2' as any
                }
                onClick={() => {
                  activate()
                }}
              >
                作業続ける
              </Button>
              <Button
                type='submit'
                className='h-14 w-full items-center'
                buttonType={Button.buttonType.outline}
                onClick={() => {
                  logOut()
                  window.location.reload()
                }}
              >
                ログアウト
              </Button>
            </div>
          </div>
        </div>
      )}
      {isIdle && (
        <div className='fixed z-[10000] grid h-screen w-screen items-center justify-center bg-gray-500'>
          <div className='w-[480px] rounded-lg border-[1px] border-blue-800/50 bg-white px-4 py-5 shadow-lg'>
            <div className='relative mb-5 h-14 w-full'>
              <LemonImage
                height={54}
                width={150}
                layout='fill'
                className='object-contain'
                alt='Lemon Square'
                src={LemonLogo}
              />
            </div>
            <div className='mb-5 text-base font-semibold'>
              ログアウトされました。
            </div>
            <div className='mt-4 w-full space-y-4'>
              <Button
                type='submit'
                className='h-14 w-full items-center'
                buttonType={
                  'flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2' as any
                }
                onClick={() => {
                  window.location.reload()
                }}
              >
                ログイン
              </Button>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  )
}

export { ActivityDetection }
